<template>
  <div class="setup__company">
    <h1>{{ $t('nav_settings_company') }}</h1>
    <p>{{ $t('signup_company_text') }}</p>

    <form-company-info
      :data="data"
      ref="form"
      @changeSubmittableState="toggleButtonState"
      @submit="submit"
    />

    <v-row>
      <v-col cols="12" class="text-right">
        <v-tooltip bottom nudge-bottom="12" :disabled="!isButtonDisabled">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                class="pkmn-button pkmn-button--full-width-mobile"
                color="primary"
                :disabled="isButtonDisabled"
                @click="$refs.form.submitForm"
              >
                {{ $t('signup_step_billing') }} →
              </v-btn>
            </span>
          </template>
          <span>{{ $t('error_fill_all') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import helpers from '@/helpers'
import paymentHelpers from '@/helpers/payment'
import userHelpers from '@/helpers/user'

import FormCompanyInfo from '@/components/Settings/FormCompanyInfo'

export default {
  name: 'SetupCompany',
  components: { FormCompanyInfo },
  data() {
    return {
      isButtonDisabled: true
    }
  },
  computed: {
    data() {
      return this.$store.state.companyData
    }
  },
  methods: {
    toggleButtonState(isSubmittable) {
      this.isButtonDisabled = !isSubmittable
    },
    async submit() {
      helpers.sendTrackingEvent('ONBOARDING', 'submit_company_info')
      // after company creation & before billing
      try {
        const companyId = this.$store.state.companyData.id
        // get the list of company subscription
        helpers.saveCurrentCompanyId(companyId)
        const companyPackages =
          await paymentHelpers.fetchCompanyPricingPackages(companyId)
        // add the company creator to the package he/she selected
        const preselectedPackageId =
          this.$store.state.companyCreatorPackage.package_id
        const selectedSubscription = companyPackages.find(
          (item) => item.package_id === preselectedPackageId
        )
        // fetch the profile of user in company
        const employeeProfileInCompany =
          await userHelpers.fetchUserProfileInCompany(
            helpers.getTokenInfo().uid,
            companyId
          )
        // add the user to the company package
        await paymentHelpers.addEmployeeToCompanyPackage(
          employeeProfileInCompany.id,
          selectedSubscription.subscription_id
        )

        this.$emit('proceed', 4)
      } catch (error) {
        this.$store.dispatch('setSystemMessage', this.$t('g_internal_error'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
</style>
