<template>
  <div class="text-left">
    <v-form
      ref="form"
      class="pkmn-form"
      @keyup.native.enter.prevent="submitForm"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="formData.name"
            class="pkmn-input"
            :label="$t('settings_company_name')"
            :rules="[rules.required]"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="formData.address"
            class="pkmn-input"
            :label="$t('g_address')"
            :rules="[rules.required]"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="6">
          <v-text-field
            v-model="formData.city"
            class="pkmn-input pkmn-input--inline"
            :label="$t('g_city')"
            :rules="[rules.required]"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="formData.post_code"
            class="pkmn-input"
            :label="$t('g_postal_code')"
            :rules="[rules.required]"
            @blur="
              formData.post_code = helpers.keepAlphanumeric(formData.post_code)
            "
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="formData.business_id"
            class="pkmn-input"
            :label="$t('settings_company_business_id')"
            :rules="[rules.required]"
            @blur="
              formData.business_id = helpers.keepAlphanumeric(
                formData.business_id
              )
            "
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row dense v-if="isItalian">
        <v-col cols="12">
          <v-text-field
            v-model="formData.fiscal_code"
            class="pkmn-input"
            :label="$t('italian_fiscal_code')"
            :rules="[rules.required]"
            @blur="
              formData.fiscal_code = helpers.keepAlphanumeric(
                formData.fiscal_code
              )
            "
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row dense v-if="isItalian">
        <v-col cols="12">
          <v-text-field
            v-model="formData.vat_code"
            class="pkmn-input"
            :label="$t('italian_vat_code')"
            :rules="[rules.required]"
            @blur="
              formData.vat_code = helpers.keepAlphanumeric(formData.vat_code)
            "
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row dense v-if="errorMessage">
        <v-col cols="12" class="text-right">
          <p class="pkmn-form__error" v-html="errorMessage"></p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import pick from 'lodash/pick'
import api from '@/helpers/api'
import companyHelpers from '@/helpers/company'
import helpers from '@/helpers'
import rules from '@/helpers/validation'
import signupHelpers from '@/components/Signup/signup_helpers'

export default {
  name: 'FormCompanyInfo',
  props: ['data'],
  data() {
    return {
      formData: {
        name: '',
        address: '',
        city: '',
        post_code: '',
        business_id: ''
        // ,
        // fiscal_code: '',
        // vat_code: ''
      },
      rules: rules,
      errorMessage: null,
      helpers: helpers
    }
  },
  computed: {
    // @todo: recheck this logic
    isItalian() {
      let result
      try {
        if (this.$store.state.companyData.country.toLowerCase() === 'italy') {
          result = true
        }
      } catch (exception) {
        result = false
      }
      return result
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.prefillData()
      }
    },
    formData: {
      deep: true,
      handler() {
        var isSubmittable = signupHelpers.isCompanyInfoFilled(this.formData)
        this.$emit('changeSubmittableState', isSubmittable)
      }
    }
  },
  methods: {
    /**
     * Validate form
     * Submit if validation passed
     * Display feedback to user
     */
    submitForm() {
      this.errorMessage = null
      // in the case of creating company
      if (!this.data) {
        // add the user id of company creator
        this.formData.user_id = helpers.getTokenInfo().uid
        // country of company is the same of the creator
        this.formData.country_code =
          this.$store.state.userData.country_code.toLowerCase()
      }

      if (!this.$refs.form.validate()) {
        return
      }

      const apiSettings = {
        method: this.data ? 'put' : 'post',
        service: 'users',
        url: this.data ? `v1/companies/${this.data.id}` : 'v1/companies',
        data: this.formData
      }

      const onSuccess = async ({ companies }) => {
        helpers.saveStorage('company', companies.id)
        await companyHelpers.fetchCompanyInfo(companies.id)
        this.$emit('submit')
      }

      const onFailed = () => {
        // write a function to "translate backend error object into text"
        // @todo: make this into a translatable message
        this.errorMessage = this.$t('business_id_exist')
      }

      api.call(apiSettings, onSuccess, onFailed)
    },
    prefillData() {
      if (this.data) {
        let fields = ['name', 'address', 'city', 'post_code', 'business_id']
        if (this.isItalian) {
          fields = fields.concat(['fiscal_code', 'vat_code'])
        }
        this.formData = pick(this.data, fields)
      }
    },
    removeSpace(value) {
      this.formData.business_id = value.trim()
    }
  },
  mounted() {
    this.prefillData()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
</style>
