<template>
  <ul class="settings-sidebar">
    <li
      class="sidebar__step"
      :class="userNameClasses"
      step="1"
      @click="selectStep(1)"
    >
      <span class="step__number">
        <span>1</span>
      </span>
      <span class="step__text">
        {{ $t('signup_enter_name') }}
      </span>
    </li>
    <li class="step__line d-md-none"></li>

    <li
      class="sidebar__step"
      :class="userPackageClasses"
      step="2"
      @click="selectStep(2)"
    >
      <span class="step__number">2</span>
      <span class="step__text">
        {{ $t('select_plan_title') }}
      </span>
    </li>
    <li class="step__line d-md-none"></li>

    <li
      class="sidebar__step"
      :class="companyInfoClasses"
      step="3"
      @click="selectStep(3)"
    >
      <span class="step__number">
        <span>3</span>
      </span>
      <span class="step__text">
        {{ $t('signup_step_company') }}
      </span>
    </li>
    <li class="step__line d-md-none"></li>

    <li
      class="sidebar__step"
      :class="billingInfoClasses"
      step="4"
      @click="selectStep(4)"
    >
      <span class="step__number">4</span>
      <span class="step__text">
        {{ $t('signup_step_billing') }}
      </span>
    </li>
  </ul>
</template>

<script>
import pick from 'lodash/pick'
import signupHelpers from '@/components/Signup/signup_helpers'

export default {
  name: 'SetupSidebar',
  data() {
    return {
      currentStep: 1
    }
  },
  computed: {
    isCompanyInfoComplete() {
      const companyInfo = this.$store.state.companyData
        ? pick(this.$store.state.companyData.company_info, [
            'name',
            'address',
            'city',
            'postal_code',
            'business_id'
          ])
        : null

      return signupHelpers.isCompanyInfoFilled(companyInfo)
    },
    isUserNameFilled() {
      const userInfo = this.$store.state.userData
      return userInfo
    },
    isPackageSelected() {
      return this.$store.state.companyCreatorPackage
    },
    userNameClasses() {
      return {
        'sidebar__step--selected': this.currentStep === 1,
        'sidebar__step--completed':
          this.currentStep !== 1 && this.isUserNameFilled
      }
    },
    userPackageClasses() {
      return {
        'sidebar__step--selected': this.currentStep === 2,
        'sidebar__step--completed': this.isPackageSelected,
        'sidebar__step--disabled': this.currentStep < 2 // @todo - come back to this later
      }
    },
    companyInfoClasses() {
      return {
        'sidebar__step--selected': this.currentStep === 3,
        'sidebar__step--completed': this.isCompanyInfoComplete,
        'sidebar__step--disabled':
          !this.isUserNameFilled || !this.isPackageSelected // @todo - come back to this later
      }
    },
    isBillingInfoComplete() {
      const billingInfo = this.$store.state.companyData
        ? this.$store.state.companyData.billing_info
        : null

      return signupHelpers.isBillingInfoFilled(billingInfo)
    },
    billingInfoClasses() {
      return {
        'sidebar__step--selected': this.currentStep === 4,
        'sidebar__step--completed':
          this.currentStep !== 4 && this.isBillingInfoComplete,
        'sidebar__step--disabled': !this.isCompanyInfoComplete
      }
    }
  },
  methods: {
    /**
     * Style the step number
     * Load corresponding form
     */
    selectStep(step) {
      this.currentStep = step
      this.$emit('changed', step)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.settings-sidebar {
  list-style: none;
  padding: 0;
}

.sidebar__step {
  @include text-h3;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;

  &:not(:first-child):before {
    content: '';
    display: block;
    height: 32px;
    width: 100%;
    border-left: 2px solid $text-color-light;
    margin-left: 13px;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  &--completed,
  &--selected {
    opacity: 1;
    color: $text-color-dark;
  }
}

$step-number-size: 28px;

.step__number {
  position: relative;
  display: inline-block;
  width: $step-number-size;
  height: $step-number-size;
  line-height: $step-number-size - 4;
  text-align: center;
  border: 2px solid $text-color-light;
  border-radius: 50%;

  .sidebar__step--selected & {
    border-color: $color-brand;
    background-color: $color-brand;
    color: #fff;
  }

  .sidebar__step--completed:not(.sidebar__step--selected) &::after {
    position: absolute;
    width: $step-number-size;
    height: $step-number-size;
    border-radius: 50%;
    top: -2px;
    left: -2px;

    font-family: 'Material Design Icons';
    // content: '\2713';
    content: '\F012C';
    border: 2px solid $text-color-dark;
    background-color: $text-color-dark;
    color: #fff;
  }
}

.step__text {
  display: inline-block;
  padding: 4px 12px;

  .sidebar__step--completed & {
    color: $text-color-dark;
  }
}

// sidebar responsive for tablet and down
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .settings-sidebar {
    display: grid;
    grid-template-columns: repeat(3, $step-number-size 1fr) $step-number-size;
    margin-bottom: 16px;
  }

  .sidebar__step {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }

    &:not(:first-child):before {
      display: none;
    }
  }

  .step__line {
    height: 2px;
    background: $text-color-dark;
    align-self: center;
    opacity: 0.2;
  }

  .step__text {
    display: none;
  }
}

@media #{map-get($display-breakpoints, 'sm-only')} {
  .settings-sidebar {
    margin-bottom: 32px;
  }
}
</style>
