<template>
  <div class="setup__user-package">
    <h1>{{ $t('select_plan_title') }}</h1>
    <p>{{ $t('select_plan_message') }}</p>

    <ul class="pkmn-fullwidth-select">
      <li
        class="fullwidth-select__item"
        v-for="pricingPackage in packageOptions"
        :key="pricingPackage.package_id"
        @click="select(pricingPackage)"
      >
        <div class="select-item__two-column-content">
          <div class="select-item__content-main">
            {{ pricingPackage.name }}
          </div>
          <div class="select-item__content-sub">
            {{ paymentHelpers.getPackageDescription(pricingPackage) }}
          </div>
          <v-icon class="select-item__content-icon"> mdi-chevron-right </v-icon>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import helpers from '@/helpers'
import paymentHelpers from '@/helpers/payment'

export default {
  name: 'SetupUserPackage',
  data() {
    return {
      packageOptions: [],
      paymentHelpers: paymentHelpers
    }
  },
  methods: {
    select(pricingPackage) {
      helpers.sendTrackingEvent('ONBOARDING', 'submit_user_package')
      // @todo: save selected package somewhere, to use after company is created
      this.$store.state.companyCreatorPackage = pricingPackage
      this.$emit('proceed', 3)
    }
  },
  async mounted() {
    const countryCode = this.$store.state.userData.country_code.toLowerCase()
    this.packageOptions =
      await paymentHelpers.getDefaultCountryLevelPackages(countryCode)
  }
}
</script>
