<template>
  <div class="setup__user-name">
    <h1>{{ $t('signup_enter_name') }}</h1>

    <v-form ref="form" @submit.prevent="submit">
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="formData.name"
            class="pkmn-input"
            single-line
            validate-on-blur
            :rules="[rules.required]"
            :label="$t('full_name')"
            @blur="trimInput"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn
          class="signup--phone__button pkmn-button pkmn-button--circle"
          color="primary"
          @click="submit"
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import helpers from '@/helpers'
import userHelpers from '@/helpers/user'
import rules from '@/helpers/validation'

export default {
  name: 'SetupUserName',
  data() {
    return {
      formData: {
        name: ''
      },
      rules: rules
    }
  },
  methods: {
    trimInput() {
      // also remove multiple spaces
      this.formData.name = this.formData.name.trim().replace(/\s\s+/g, ' ')
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return
      }
      helpers.sendTrackingEvent('ONBOARDING', 'submit_user_name')
      try {
        await userHelpers.editUser(this.$store.state.userData.id, {
          first_name: this.formData.name,
          last_name: ' '
        })
        this.$emit('proceed', 2)
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    }
  },
  mounted() {
    this.formData.name = this.$store.getters.userName
  }
}
</script>
