<template>
  <v-row class="page page--signup">
    <v-col cols="12" sm="8" offset-sm="2" md="3" offset-md="0">
      <setup-sidebar ref="sidebar" @changed="changeStep"></setup-sidebar>
    </v-col>
    <v-col
      cols="12"
      sm="8"
      offset-sm="2"
      offset-md="1"
      md="5"
      class="signup__form text-left"
    >
      <transition name="component-fade" mode="out-in">
        <component
          :is="currentForm"
          :data="data"
          @proceed="changeStep"
          @complete="completeSignup"
        />
      </transition>
    </v-col>
  </v-row>
</template>

<script>
import companyHelpers from '@/helpers/company'
import helpers from '@/helpers'
import userHelpers from '@/helpers/user'

import SetupSidebar from '@/components/Signup/SetupSidebar'

import Billing from '@/components/Setup/Billing'
import Company from '@/components/Setup/Company'
import UserName from '@/components/Setup/UserName'
import UserPackage from '@/components/Setup/UserPackage'

export default {
  name: 'SetupView',
  components: { SetupSidebar },
  data() {
    return {
      currentForm: null,
      formData: null,
      isFirstLoad: true, // trick this to trigger form fill on first load
      formMdColumns: 5
    }
  },
  computed: {
    data() {
      return this.$store.state.companyData
    }
  },
  watch: {
    '$store.state.companyData': {
      deep: true,
      handler() {
        if (this.isFirstLoad) {
          // this.changeStep(1)
          // this.isFirstLoad = false

          this.$nextTick(() => {
            this.hideTopNavOnMobileFirstLoad()
          })
        }
      }
    }
  },
  methods: {
    changeStep(step) {
      switch (step) {
        case 2:
          this.currentForm = UserPackage
          helpers.sendTrackingEvent('ONBOARDING', 'show_fill_user_package')
          break
        case 3:
          this.currentForm = Company
          helpers.sendTrackingEvent('ONBOARDING', 'show_fill_company_info_page')
          break
        case 4:
          this.currentForm = Billing
          helpers.sendTrackingEvent(
            'ONBOARDING',
            'show_add_company_billing_info_page'
          )
          break
        default:
          this.currentForm = UserName
          helpers.sendTrackingEvent('ONBOARDING', 'show_fill_user_name')
          break
      }
      this.hideTopNavOnMobileFirstLoad()
      this.$refs.sidebar.currentStep = step
    },
    async completeSignup() {
      await companyHelpers.fetchCompanyInfo(this.$store.state.companyData.id)
      helpers.sendTrackingEvent(
        'ONBOARDING',
        'complete_business_account_setup',
        { business_id: this.$store.state.companyData.business_id.slice(0, 7) }
      )
      // redirect user to after-login page
      this.$router.push({
        path: '/home',
        query: this.$route.query
      })
    },
    hideTopNavOnMobileFirstLoad() {
      // for mobile: take user to top when changing step
      if (document.documentElement.clientWidth < 600) {
        window.scrollTo(0, 80)
      }
    }
  },
  async mounted() {
    await userHelpers.fetchUserInfo()
    // if user filled full name, start with step 2
    this.changeStep(this.$store.getters.userName ? 2 : 1)
    this.isFirstLoad = false

    helpers.sendTrackingEvent('ONBOARDING', 'show_company_setup_flow')
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.page--signup {
  margin-top: 72px !important;
  margin-bottom: 200px !important;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-top: -48px !important;
    margin-bottom: 48px !important;
    min-height: calc(100vh - 120px);
    align-content: flex-start;
  }
}

.signup__form {
  margin-top: -12px;
}
</style>
