<template>
  <div class="setup__biling">
    <h1>{{ $t('nav_settings_billing') }}</h1>
    <p>{{ $t('signup_billing_text') }}</p>

    <form-billing-info
      ref="form"
      :groupAccountId="defaultGroupAccountId"
      @changeSubmittableState="toggleButtonState"
      @submit="submit"
    />

    <v-row class="pkmn-form__button-wrap">
      <v-col cols="6" class="text-left">
        <v-btn
          class="pkmn-button pkmn-button--no-padding button-previous"
          text
          color="primary"
          @click="goToPreviousStep"
        >
          ← {{ $t('signup_prev_step') }}
        </v-btn>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-tooltip bottom nudge-bottom="12" :disabled="!isButtonDisabled">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                class="pkmn-button pkmn-button--full-width-mobile"
                color="primary"
                :disabled="isButtonDisabled"
                @click="$refs.form.submitForm"
              >
                {{ $t('signup_complete_button') }} →
              </v-btn>
            </span>
          </template>
          <span>{{
            isBraintreeLoaded
              ? $t('error_fill_all')
              : $t('settings_billing_card_service_loading')
          }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import companyHelpers from '@/helpers/company'
import helpers from '@/helpers'

import FormBillingInfo from '@/components/Settings/FormBillingInfo'

export default {
  name: 'SetupBilling',
  components: { FormBillingInfo },
  data() {
    return {
      isButtonDisabled: true,
      isBraintreeLoaded: false
    }
  },
  computed: {
    defaultGroupAccountId() {
      const groupList = this.$store.state.groupData
      if (!groupList) {
        return null
      }

      return Math.min(...groupList.map((group) => group.account_id))
    }
  },
  methods: {
    toggleButtonState(isSubmittable) {
      this.isButtonDisabled = !isSubmittable
    },
    goToPreviousStep() {
      helpers.sendTrackingEvent('ONBOARDING', 'click_back_to_company_info')
      this.$emit('proceed', 3)
    },
    submit() {
      helpers.sendTrackingEvent(
        'ONBOARDING',
        'click_complete_business_account_setup'
      )
      this.$emit('complete')
    },
    async loadData() {
      // re-fetch dashboard data to set default group as selected
      await helpers.fetchDashboardData()
      const company = this.$store.state.companyData

      // fetch payment options and "translate" them to consistent frontend variables
      await companyHelpers.fetchPaymentOptions(company.country.code)
    }
  },
  async mounted() {
    if (!this.$store.state.paymentOptions) {
      await this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.button-previous {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-bottom: 16px;
  }
}
</style>
